<a class="tcm-notification"
      (click)="checkSelectedClient()"
     [href]="this.redirectUrl"
     [ngStyle]="{'cursor' : this.clientId == this.selectedClientId ? 'pointer' : 'default'}"
>
  <div>
    <ng-container *ngIf="requester != null">
      <div
        *ngIf="requester.imagePath != null && requester.imagePath != ''; else noRequesterImageUrlBlock"
        class="tcm-userImage"
        [style.background-image]="'url(' + requester.imagePath + ')'"
      ></div>

      <ng-template #noRequesterImageUrlBlock>
        <div class="tcm-circleUserInfo">
          <span class="initials">{{ (requester.firstName[0] || '') + (requester.lastName[0] || '') }} </span>
        </div>
      </ng-template>
    </ng-container>

    <div class="content">
      <span class="subject">{{ subject }}</span>
      <span class="text">{{ text }}</span>
      <span class="title"> {{ taskTitle }}</span>
    </div>
  </div>
</a>
