import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ITaskManagementCustomFilterData } from '../../dashboard/communication/task-management-page/helpers/task-management-custom-filter-data';
import { ISubTaskBM } from '../bm/subTaskBM.interface';
import { ITaskBM } from '../bm/taskBM.interface';
import { ICommunication } from '../interfaces/communication.interface';
import { IGridSearchResponse } from '../interfaces/grid-search-response.interface';
import { ISubTask } from '../interfaces/sub-task.interface';
import { ITaskDetailsDto } from '../interfaces/task-details-dto.interface';
import { ITask } from '../interfaces/task.interface';
import { ITasksDTO } from '../interfaces/tasksDTO.interface';
import { IKeyValue } from '../interfaces/keyvalue.interface';
import { IExportTask } from '../interfaces/export-task.interface';
import { TaskStatusEnum } from '../enums/TaskStatusEnum';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private serviceBaseUrl = '';

  constructor(
    private http: HttpClient,
    @Inject('API_BASE_URL') private baseUrl: string,
    private formBuilder: FormBuilder
  ) {
    this.serviceBaseUrl = `${this.baseUrl}/Task`;
  }

  //#region API
  gridSearch(
    queryParams?: string,
    customFilterData: ITaskManagementCustomFilterData | null = null
  ): Observable<IGridSearchResponse<ITask>> {
    let params = new HttpParams();
    if (customFilterData != null) {
      Object.keys(customFilterData).forEach((prop) => {
        if (customFilterData[prop] != null) {
          if (Array.isArray(customFilterData[prop])) {
            customFilterData[prop].forEach((x: any) => {
              params = params.append(prop, x);
            });
          } else {
            params = params.set(prop, JSON.stringify(customFilterData[prop]));
          }
        }
      });
    }

    return this.http.get<IGridSearchResponse<ITask>>(`${this.serviceBaseUrl}/grid-search?${queryParams}`, {
      params: params
    });
  }

  public getTaskDetails(clientId: number, taskId: number): Observable<ITaskDetailsDto> {
    let params: HttpParams = new HttpParams();
    params = params.set('clientId', JSON.stringify(clientId)).set('taskId', JSON.stringify(taskId));

    return this.http.get<ITaskDetailsDto>(`${this.serviceBaseUrl}/task-details`, { params: params });
  }

  public searchTasks(name: string, categoryId: number): Observable<ITasksDTO[]> {
    const params = new HttpParams().set('name', name).set('categoryId', categoryId);
    return this.http.get<ITasksDTO[]>(`${this.serviceBaseUrl}/search`, { params: params });
  }

  public addTask(task: ITaskBM): Observable<ITask> {
    return this.http.post<ITask>(`${this.serviceBaseUrl}/add`, task);
  }

  public updateTask(task: ITaskBM): Observable<ITask> {
    return this.http.put<ITask>(`${this.serviceBaseUrl}/update`, task);
  }

  public getAllDepartments(): Observable<any> {
    return this.http.get<any>(`${this.serviceBaseUrl}/departments`);
  }

  public getAllTaskStatuses(): Observable<any> {
    return this.http.get<any>(`${this.serviceBaseUrl}/task-statuses`);
  }

  public getAllClientActiveUsers(clientId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('clientId', clientId);

    return this.http.get<any>(`${this.serviceBaseUrl}/active-client-users`, { params: params });
  }

  public searchAllActiveClientUsers(clientId: number, searchTerm: string): Observable<IKeyValue[]> {
    let params = new HttpParams();
    params = params.append('searchTerm', searchTerm);
    return this.http.get<IKeyValue[]>(`${this.serviceBaseUrl}/search-active-client-users/${clientId}`, {
      params: params
    });
  }

  public getCommunication(taskId: number): Observable<ICommunication> {
    return this.http.get<ICommunication>(`${this.serviceBaseUrl}/communication/${taskId}`);
  }

  public archiveTasks(taskIds: number[]): Observable<ITasksDTO[]> {
    return this.http.post<ITasksDTO[]>(`${this.serviceBaseUrl}/archive`, taskIds);
  }

  exportTasks(
    queryParams?: string,
    customFilterData: ITaskManagementCustomFilterData | null = null
  ): Observable<IGridSearchResponse<IExportTask>> {
    let params = new HttpParams();
    if (customFilterData != null) {
      Object.keys(customFilterData).forEach((prop) => {
        if (customFilterData[prop] != null) {
          if (Array.isArray(customFilterData[prop])) {
            customFilterData[prop].forEach((x: any) => {
              params = params.append(prop, x);
            });
          } else {
            params = params.set(prop, JSON.stringify(customFilterData[prop]));
          }
        }
      });
    }

    return this.http.get<IGridSearchResponse<IExportTask>>(`${this.serviceBaseUrl}/export-tasks?${queryParams}`, {
      params: params
    });
  }

  //#endregion

  //#region Helpers
  public createTaskForm(params: { clientId?: number; task?: ITask }): FormGroup {
    let task: ITask | undefined = params.task;
    let clientId: number | undefined = params.clientId;

    let date: Date | null = null;
    if (task?.dueDate != null && task?.dueDate != undefined) {
      date = new Date(task?.dueDate);
    }

    return this.formBuilder.group({
      clientId: [task ? task.clientId : clientId],
      id: [task ? task.id : 0],
      title: [task ? task.title : '', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
      description: [task ? task.description : '', [Validators.maxLength(250)]],
      taskCategoryId: [task ? task.taskCategoryId : '', [Validators.required]],
      taskStatusId: [task ? task.taskStatusId : TaskStatusEnum.New, [Validators.required]],
      departmentId: [task ? task.departmentId : '', [Validators.required]],
      assignee: [task ? task.assignee : '', [Validators.required]],
      dueDate: [date],
      communicationId: [task ? task.communicationId : ''],
      taskTagIds: [
        task
          ? task.taskTaskTag.map((el) => {
              return el.taskTagId;
            })
          : []
      ]
    });
  }
  //#endregion
}
