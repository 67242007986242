import { Pipe, PipeTransform } from '@angular/core';
import { accessRights } from '../helpers/accessRights';

@Pipe({
  name: 'displayAccessRightsName',
  pure: false
})
export class DisplayAccessRightsName implements PipeTransform {
  transform(id: number): string {
    const aR = accessRights;
    if (!id) {
      return '';
    }
    return aR.find((e) => e.id === id).name;
  }
}
