import { Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { TaskNotificationTemplateComponent } from '../shared/components/task-notification-template/task-notification-template.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  @ViewChild('appendTo', { read: ViewContainerRef }) public: ViewContainerRef;

  constructor(private notificationSvc: NotificationService, private translationSvc: TranslateService) {}

  success = (msg) => {
    const content = this.translationSvc.instant(msg);
    this.notificationSvc.show({
      content: content,
      cssClass: 'button-notification',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: 5000,
      closable: false
    });
  };
  error = (msg) => {
    const content = this.translationSvc.instant(msg);

    this.notificationSvc.show({
      content: content,
      cssClass: 'button-notification',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'error', icon: true },
      hideAfter: 5000,
      closable: false
    });
  };
  info = (msg) => {
    const content = this.translationSvc.instant(msg);

    this.notificationSvc.show({
      content: content,
      cssClass: 'button-notification',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'info', icon: true },
      hideAfter: 5000,
      closable: false
    });
  };
  taskInfo = (data) => {
    const notificationRef: NotificationRef = this.notificationSvc.show({
      content: TaskNotificationTemplateComponent,
      cssClass: 'sf-notification-clean',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'none', icon: true },
      hideAfter: 5000,
      closable: false
    });
    let notificationContent = notificationRef.content?.instance;
    notificationContent.setValues(
      data.message.subject,
      data.message.text,
      data.taskTitle,
      data.url,
      data.requester,
      data.clientId
    );
  };
}
