import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { IUserInfo } from '../interfaces/user-info.interface';
import { Router } from '@angular/router';
import { IUser } from '../interfaces/user.interface';
import { IBaseStringRequest } from '../interfaces/baseStringRequest.interface';

const groups = [
  'e8f01862-0e0b-41fb-8e40-c30158188eac',
  '6561addc-6942-436f-b9ca-08d7dce92f31',
  'a388ee88-7f70-4b9a-b2f2-2765f7d2e9df',
  '00f52825-15e9-46d9-aa2e-5d13e7218085',
  '62fbdba1-672c-49b2-9a98-96414f7ad343',
  '3b85519e-d4d3-48ce-9e07-2983fc5dcb5d'
];
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, @Inject('API_BASE_URL') private apiBaseUrl: string, private router: Router) {}

  getUserByEmail(email: string): Observable<IUser> {
    const baseStringRequest: IBaseStringRequest = {
      value: email
    };
    return this.http.post<any>(`${this.apiBaseUrl}/user/get`, baseStringRequest);
  }

  getUserContactInfo(): Observable<IUserInfo> {
    return this.http.get<IUserInfo>(`${this.apiBaseUrl}/Home/get-user-info`).pipe(
      retry(2),
      catchError((e) => {
        throw e;
      })
    );
  }

  checkUserValidity(accountId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiBaseUrl}/user/check-user-azure/${accountId}`).pipe(retry(1));
  }

  getUserRoles(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiBaseUrl}/user/get-roles`).pipe(
      catchError((err: HttpErrorResponse) => {
        //error: "invalid_grant"
        if (err.error.error === 'invalid_grant') {
          this.router.navigate(['/forbidden']);
        }
        return throwError(err);
      })
    );
  }

  getUserComponentAccessRightsId(userId: number, clientId: number, serviceTypeId): Observable<number> {
    let params: HttpParams = new HttpParams();
    params = params
      .set('clientId', JSON.stringify(clientId))
      .set('serviceTypeId', JSON.stringify(serviceTypeId))
      .set('userId', JSON.stringify(userId));

    return this.http.get<number>(`${this.apiBaseUrl}/User/get-access-rights`, { params: params });
  }

  getUserSessionLength(): Observable<{ value: number }> {
    return this.http.get<{ value: number }>(`${this.apiBaseUrl}/Home/get-session-lock-timeout`);
  }

  getDefaultLanguage(): Observable<{ lang: string }> {
    return this.http.get<{ lang: string }>(`${this.apiBaseUrl}/user/get-my-language`);
  }

  updateDefaultLanguage(lang: string): Observable<{ lang: boolean }> {
    return this.http.get<{ lang: boolean }>(`${this.apiBaseUrl}/user/update-my-language?lang=${lang}`);
  }

  updateActiveClient(userId: number, clientId: number): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/User/update-active-client`, {
      id: userId,
      clientId: clientId
    });
  }

  isAcceptedTermsAndConditions(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiBaseUrl}/User/terms-and-conditions`).pipe(
      retry(2),
      catchError((e) => {
        throw e;
      })
    );
  }

  AcceptTermsAndConditions(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiBaseUrl}/User/accept-terms-and-conditions`);
  }
}
