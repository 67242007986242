import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Observable } from 'rxjs';
import { IGridSearchResponse } from '../../../core/interfaces/grid-search-response.interface';
import { IKeyValue } from '../../../core/interfaces/keyvalue.interface';
import { ITaskCategory } from '../../../core/interfaces/task-category.interface';

@Injectable()
export class TaskCategoryService {
  controllerPrefix = 'TaskCategory';
  constructor(
    private http: HttpClient,
    @Inject('API_BASE_URL') private baseUrl: string,
    private fb: FormBuilder,
    private translate: TranslateService,
    private notificationSvc: NotificationService
  ) {}

  getAllTaskCategoriesDropdown(clientId: number): Observable<IKeyValue[]> {
    return this.http.get<IKeyValue[]>(`${this.baseUrl}/${this.controllerPrefix}/get-all/${clientId}`);
  }

  getAllTaskCategories(clientId: number): Observable<ITaskCategory[]> {
    return this.http.get<ITaskCategory[]>(`${this.baseUrl}/${this.controllerPrefix}/get-all/${clientId}`);
  }

  updateTaskCategory(taskCategory: ITaskCategory) {
    return this.http.post(`${this.baseUrl}/${this.controllerPrefix}/update`, taskCategory);
  }

  addTaskCategory(taskCategory: ITaskCategory) {
    return this.http.post(`${this.baseUrl}/${this.controllerPrefix}/add`, taskCategory);
  }

  deleteTaskCategory(id: number) {
    return this.http.delete<string[]>(`${this.baseUrl}/${this.controllerPrefix}/delete/${id}`);
  }

  searchTaskCategories(clientId: number, queryParams?: string): Observable<IGridSearchResponse<ITaskCategory>> {
    return this.http.get<IGridSearchResponse<ITaskCategory>>(
      `${this.baseUrl}/${this.controllerPrefix}/search/${clientId}?${queryParams}`
    );
  }

  createTaskCategoryForm(data?: ITaskCategory): FormGroup {
    return this.fb.group(
      {
        id: [data ? data.id : 0],
        name: [data ? data.name : '', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]]
      },
      [Validators.required]
    );
  }
}
