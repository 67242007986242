import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AuthServiceWrapper } from '../core/services/auth.service';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnDestroy {
  supportedLangs: string[] = ['en', 'sv', 'fi', 'no'];
  selectedLanguage: string;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public translate: TranslateService,
    public auth: AuthServiceWrapper,
    private router: Router,
    private googleAnalyticsSvc: GoogleAnalyticsService
  ) {
    let lang = this.route.snapshot.data.language;
    if (!this.supportedLangs.includes(lang)) {
      lang = 'en';
    }
    this.translate.use(lang);
    if (!!this.auth.getAccount()) {
      this.router.navigate(['/home', 'dashboard']);
    }
    this.googleAnalyticsSvc.sendUnknownTag();
  }
  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  clearSessionLock() {
    localStorage.removeItem('session-lock');
    this.router.navigate(['/home', 'dashboard']);
  }
}
