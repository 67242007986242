import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _active: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public active: Observable<boolean> = this._active.asObservable();
  constructor() {}

  set activeLoader(val) {
    this._active.next(val);
  }
}
