<div class="s-mainContainer">
  <a [routerLink]="['/home', 'dashboard']" class="s-display-block s-margin-top-1 s-text-center">
    <img src="../../assets/img/newsec-logo.svg" alt="logo" height="30" />
  </a>
  <div class="s-margin-top-9">
    <h1 class="title s-text-transform-none s-font-compressed s-text-center">404 {{ '404title' | translate }}</h1>
    <h3 class="subtitle s-text-center">{{ '404subtitle' | translate }}</h3>
    <button class="sf-button sf-primary s-margin-top-4 s-margin-auto" [routerLink]="['/home', 'dashboard']">
      {{ '404button' | translate }}
    </button>
  </div>
</div>

<style>
  .title {
    font-weight: 700;
    font-size: 60px;
    line-height: 55px;
    letter-spacing: -0.393443px;
    text-transform: capitalize;
    color: #1a1a1a;
  }
  .subtitle {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height, or 120% */
    color: #1a1a1a;
    letter-spacing: -0.0177778px;
  }
</style>
<!--
<div class="page404">
  <a [routerLink]="['/home', 'dashboard']" class="home-link">
    <img src="../../assets/img/newsec-logo.svg" alt="logo" height="48" />
  </a>
  <div class="vertical-center">
    <div class="page-hero-area error">
      <div
        class="background"
        style="
          background-image: url('https://www.newsec.com/globalassets/images/startpage-hero-1140x340/newsec-baby-in-the-woods.jpg');
        ">
        <div class="overlay"></div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 offset-xs-2 col-xs-8 offset-lg-3 col-lg-6">
            <div class="header-container">
              <h1 class="h1-large">
                <span class="d-block magenta-color-1">404</span>
                <span class="d-block white">{{ '404title' | translate }} </span>
              </h1>
            </div>
            <div class="content">
              <p>{{ '404subtitle' | translate }}&nbsp;</p>
              <p>&nbsp;</p>
              <div class="text-center text-md-left">
                <a href="/" target="" class="cta-btn cta-btn--primary-dark">{{ '404button' | translate }}</a>
              </div>

              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
