import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthServiceWrapper } from '../core/services/auth.service';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { UserStore } from '../core/services/user-store.service';

@Component({
  selector: 'app-cookie-declaration',
  templateUrl: './cookie-declaration.component.html',
  styleUrls: ['../@pages/layouts/condensed/condensed.component.scss']
})
export class CookieDeclarationComponent implements OnInit, AfterViewInit {
  @ViewChild('cookieDeclaration') cookieDiv: ElementRef;

  constructor(
    private googleAnalyticsSvc: GoogleAnalyticsService,
    private authService: AuthServiceWrapper,
    private userStore: UserStore
  ) {}

  ngOnInit() {
    this.userStore.userObs$.subscribe((result) => {
      if (result) {
        this.googleAnalyticsSvc.sendTag();
      } else {
        this.googleAnalyticsSvc.sendUnknownTag();
      }
    });
  }

  ngAfterViewInit() {
    this.loadScript();
  }

  loadScript(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://consent.cookiebot.com/093c50bd-7a25-4896-bb5e-392311701993/cd.js';
    script.id = 'CookieDeclaration';
    this.cookieDiv.nativeElement.appendChild(script);
  }
}
