import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserStore } from './user-store.service';
import { UserService } from './user.service';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { catchError, filter, switchMap, tap, throttleTime } from 'rxjs/operators';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { CommunicationNotificationService } from './communication-notification.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceWrapper {
  private accessToken: string;
  public static MSAL_EVENT_ENUMS = {
    // Login events
    loginSuccess: 'msal:loginSuccess',
    loginFailure: 'msal:loginFailure',

    // Aquire token events
    aqTokenSuccess: 'msal:acquireTokenSuccess',
    aqTokenFailure: 'msal:acquireTokenFailure',

    // Silent signin events
    ssoSuccess: 'msal:ssoSuccess',
    ssoFailure: 'msal:ssoFailure'
  };

  public langDict = {
    // eslint-disable-next-line prettier/prettier
    // da: 'da',
    // dk: 'da',
    fi: 'fi',
    se: 'sv',
    sv: 'sv',
    no: 'no',
    en: 'en',
    // 'da-DK': 'da',
    'sv-SE': 'sv',
    'nb-NO': 'no',
    'fi-FI': 'fi'
  };

  public refreshSidebar = false;
  public isIE: boolean =
    window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  public isIOS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

  constructor(
    private msal: MsalService,
    private userStore: UserStore,
    private userSvc: UserService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject('USERS_BROWSER_LANG') private browserLang: string,
    private router: Router,
    private collaborationSvc: CommunicationNotificationService
  ) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        throttleTime(10000),
        catchError((err) => {
          return throwError(err);
        }),
        switchMap((tokenResponse: any) => {
          this.accessToken = tokenResponse.payload.accessToken;
          const token = tokenResponse.payload.idTokenClaims;
          const mappedLang = this.langDict[token.xms_pl]
            ? this.langDict[token.xms_pl]
            : this.langDict[this.browserLang] || 'en';
          const tokenLang = of({ lang: mappedLang });
          const dbLang = this.userSvc.getDefaultLanguage();
          return forkJoin([of(token), tokenLang, dbLang]);
        }),
        tap(([token, tokenLang, dbLang]) => {
          const t = {
            ...token,
            xms_pl: tokenLang.lang || dbLang.lang
          };
          this.userStore.tokenSet = t;
        }),
        switchMap((r) => {
          return this.userSvc.getUserContactInfo();
        })
      )
      .subscribe(async (user: any) => {
        this.userStore.userSet = user;
        if (user.communicationServiceId) {
          await collaborationSvc.initChatClient();
        }
        if (user.lastActiveClientId) {
          localStorage.setItem('selected-client-id', user.lastActiveClientId);
        }
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        throttleTime(1000),
        switchMap((tokenResponse: any) => {
          const token = tokenResponse.payload.idTokenClaims;
          const mappedLang = this.langDict[token.xms_pl] ? this.langDict[token.xms_pl] : 'en';
          return this.userSvc.updateDefaultLanguage(mappedLang);
        })
      )
      .subscribe((res) => {
        if (res.lang && environment.production) {
        }
      });
  }

  tokenHasValidLanguage(lang: string): boolean {
    const supportedLanguages = ['en', 'sv', 'fi', 'da', 'no'];
    if (!lang) {
      return false;
    }
    return supportedLanguages.includes(lang);
  }

  login(): void {
    this.router.navigate(['/home/dashboard']);
  }
  logout(): void {
    const [user] = this.msal.instance.getAllAccounts();
    localStorage.removeItem('selected-client-id');
    localStorage.removeItem('s_c_name');
    localStorage.removeItem('s_c_short_name');
    localStorage.removeItem('ns-logout');
    localStorage.setItem('ns-logout', '1');
    debugger;
    this.msal.logoutRedirect({ account: user }).pipe(
      catchError((err) => {
        return err;
      })
    );
  }

  getAccount(): number {
    return this.msal.instance.getAllAccounts().length;
  }

  acquireTokenSilent(force = true): Observable<AuthenticationResult> {
    const [account] = this.msal.instance.getAllAccounts();
    const accessTokenRequest = {
      account,
      scopes: ['user.read'],
      forceRefresh: force
    };
    return this.msal.acquireTokenSilent(accessTokenRequest);
  }

  get getAccessToken(): string {
    return this.accessToken;
  }
}
