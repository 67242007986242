import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  breadcrumbs: { name: string; link: string }[] = [];

  constructor(private _router: Router, private _route: ActivatedRoute) {
    this._router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((res) => {
      this.breadcrumbs = [];
      this.recursiveCrumbs(this._route);
      this.breadcrumbs.unshift({ name: 'Home', link: '/home/dashboard' });
    });
  }

  recursiveCrumbs(route: ActivatedRoute): any {
    if (!route) return;
    let crumbs: any = [];
    const isArray = Array.isArray(route.snapshot.data.breadcrumb);
    if (!isArray) {
      crumbs = [route.snapshot.data.breadcrumb];
    } else {
      crumbs = route.snapshot.data.breadcrumb;
    }
    for (let crumb of crumbs) {
      const crumbNames = this.breadcrumbs.map((c) => c.name);
      if (Object.keys(route.snapshot.params).length > 0 && crumb) {
        crumb.link = crumb.link.replace(':', '');
        for (const key in route.snapshot.params) {
          crumb.link = crumb.link.replace(key, route.snapshot.params[key]);
        }
      }
      if (crumb && !crumbNames.includes(crumb.name)) {
        this.breadcrumbs.push(crumb);
      }
    }
    return this.recursiveCrumbs(route.firstChild);
  }
}
