import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ns-loader',
  templateUrl: './ns-loader.component.html',
  styleUrls: ['./ns-loader.component.scss']
})
export class NsLoaderComponent {
  constructor() {}
}
