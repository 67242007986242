<div class="s-mainContainer">
  <a [routerLink]="['/home', 'dashboard']" class="s-display-block s-margin-top-1 s-text-center">
    <img src="../../assets/img/newsec-logo.svg" alt="logo" height="30" />
  </a>
  <div class="s-margin-top-9">
    <h1 class="title s-text-transform-none s-font-compressed s-text-center">
      {{ '403title' | translate }}
    </h1>
    <h3 class="subtitle s-text-center">{{ '403subtitle' | translate }}&nbsp;</h3>
    <button class="sf-button sf-primary s-margin-top-4 s-margin-auto">{{ '404button' | translate }}</button>
  </div>
</div>

<style>
  .title {
    font-weight: 700;
    font-size: 60px;
    line-height: 55px;
    letter-spacing: -0.393443px;
    text-transform: capitalize;
    color: #1a1a1a;
  }
  .subtitle {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height, or 120% */
    color: #1a1a1a;
    letter-spacing: -0.0177778px;
  }
</style>
