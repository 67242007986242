import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthServiceWrapper } from '../core/services/auth.service';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { UserStore } from '../core/services/user-store.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent {
  supportedLangs: string[] = ['en', 'sv', 'fi', 'no', 'da'];
  constructor(
    private route: ActivatedRoute,
    private translateSvc: TranslateService,
    private googleAnalyticsSvc: GoogleAnalyticsService,
    private authService: AuthServiceWrapper,
    public userStore: UserStore
  ) {
    let lang = this.route.snapshot.data.language;
    if (!this.supportedLangs.includes(lang)) {
      lang = 'en';
    }
    this.translateSvc.use(lang);
    this.userStore.userObs$.subscribe((result) => {
      if (result) {
        this.googleAnalyticsSvc.sendTag();
      } else {
        this.googleAnalyticsSvc.sendUnknownTag();
      }
    });
  }
}
