<div class="s-mainContainer">
  <a [routerLink]="['/home', 'dashboard']" class="s-display-block s-margin-top-1 s-text-center">
    <img src="../../assets/img/newsec-logo.svg" alt="logo" height="30" />
  </a>
  <div class="s-margin-top-9">
    <h1 class="title s-text-transform-none s-font-compressed s-text-center">{{ 'ThankYouTitle' | translate }}</h1>
    <h3 class="subtitle s-text-center">{{ 'ThankYouSubtitle' | translate }}</h3>
    <button class="sf-button sf-primary s-margin-top-4 s-margin-auto" [routerLink]="['/home', 'dashboard']">
      {{ '404button' | translate }}
    </button>
  </div>
</div>

<style>
  .title {
    font-weight: 700;
    font-size: 60px;
    line-height: 55px;
    letter-spacing: -0.393443px;
    text-transform: capitalize;
    color: #1a1a1a;
  }
  .subtitle {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height, or 120% */
    color: #1a1a1a;
    letter-spacing: -0.0177778px;
  }
</style>

<!-- 

<div class="contactpage">
    <a [routerLink]="['/home','dashboard']"  class="contact-logo">
      <img
      src="../../assets/img/newsec-logo.svg"
      alt="logo"
      height="48"
     
    />
    </a>
    
  
    <div class="vertical-center mainContainer">
     
    </div>
  </div>
  
  <style>
    .contactpage {
      height: 100vh;
      background: #dfe5e8;
    }
    .contactpage a.contact-logo {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 1;
    }
    .contactpage .vertical-center {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    .mainContainer {
      max-width: 800px;
      width: 90%;
      text-align: center;
    }
    h1.title {
      color: #303f47;
      font-size: 33px;
      margin-bottom: 10px;
    }
    h3.subtitle {
        color: #303f47;
      font-size: 22px;
      margin-bottom: 30px;
      font-weight: 400;
    }
    @media screen and (max-height: 1000px) {
        .contactpage {
            height: auto;
        }
        .contactpage .vertical-center {
          position: relative;
      top: unset;
      left: unset;
      transform: unset;
      margin: auto;
      padding: 150px 0;
        }
    }
  </style>
   -->
