import { Component, OnInit } from '@angular/core';
import { AuthServiceWrapper } from '../core/services/auth.service';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { UserStore } from '../core/services/user-store.service';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {
  constructor(
    private googleAnalyticsSvc: GoogleAnalyticsService,
    private authService: AuthServiceWrapper,
    private userStore: UserStore
  ) {}

  ngOnInit() {
    this.userStore.userObs$.subscribe((result) => {
      if (result) {
        this.googleAnalyticsSvc.sendTag();
      } else {
        this.googleAnalyticsSvc.sendUnknownTag();
      }
    });
  }
}
