import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translateSvc: TranslateService, @Inject('USERS_BROWSER_LANG') public browserLang: string) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lang = this.translateSvc?.currentLang || this.translateSvc?.defaultLang || this.browserLang;
    const headers = req.headers.set('language', lang);
    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}
