<!-- <h2 (click)="goToCommunications()" class="k-cursor-pointer">
  {{ message }}
  <h1 id="threadName">{{ threadName }}</h1>
</h2> -->

<div class="tcm-notification" (click)="goToCommunications()" [ngStyle]="{'cursor' : disableClick ? 'default' : 'pointer'}">
  <div>
    <ng-container *ngIf="requester != null">
      <div
        *ngIf="requester.imagePath != null && requester.imagePath != ''; else noRequesterImageUrlBlock"
        class="tcm-userImage"
        [style.background-image]="'url(' + requester.imagePath + ')'"
      >
        <img src="../../../../../assets/img/@.svg" alt="tag-icon" class="tag-item" />
      </div>

      <ng-template #noRequesterImageUrlBlock>
        <div class="tcm-circleUserInfo">
          <span class="initials">{{
            requester.senderDisplayName.split(' ')[0][0] + requester.senderDisplayName.split(' ')[1][0]
          }}</span>
          <img src="../../../../../assets/img/@.svg" alt="tag-icon" class="tag-item" />
        </div>
      </ng-template>
    </ng-container>

    <div class="content">
      <span class="subject">{{ subject }}</span>
      <span class="text">{{ message }}</span>
      <span class="title"> {{ threadName }}</span>
    </div>
  </div>
</div>
