import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private googleAnalyticsSvc: GoogleAnalyticsService
  ) {
    this.googleAnalyticsSvc.sendUnknownTag();
    this.router.navigate(['/']);
  }
}
