import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { IErrorCode } from '../interfaces/errorCode.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notificationSvc: NotificationService, private translateSvc: TranslateService) {}

  excludedRoutes = ['/get-roles'];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.isUrlExcluded(req.url)) return;
        this.parseError(error)
          .pipe(take(1))
          .subscribe((result) => {
            this.notificationSvc.error(result);
          });

        return throwError(error);
      })
    );
  }

  private parseError(httpResponseError: HttpErrorResponse): Observable<string> {
    const error = httpResponseError.error;
    let errorCode = 'Error - 0';
    if (error && error.error) {
      errorCode = `Error - ${error.error}`;
    } else if (error && error.errors[0] && error.errors[0].errorCode) {
      errorCode = `Error - ${error.errors[0].errorCode}`;
    }
    return this.translateSvc.get(errorCode);
  }

  isUrlExcluded(requestUrl: string): boolean {
    return !!this.excludedRoutes.find((excludedUrl: string) => requestUrl.includes(excludedUrl));
  }
}
