import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GroupEnum } from '../../../environments/environment';
import { IUserInfo } from '../interfaces/user-info.interface';

@Injectable({
  providedIn: 'root'
})
export class UserStore {
  private _user: BehaviorSubject<IUserInfo> = new BehaviorSubject(null);
  private _tokenMetadata: BehaviorSubject<any> = new BehaviorSubject(null);
  private _userGroups: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _companyName: BehaviorSubject<string> = new BehaviorSubject('');
  public companyName$: Observable<string> = this._companyName.asObservable();
  private _shortCompanyName: BehaviorSubject<string> = new BehaviorSubject('Unselected');
  public shortCompanyName$: Observable<string> = this._shortCompanyName.asObservable();
  public userObs$: Observable<IUserInfo> = this._user.asObservable();
  public tokenObs$: Observable<any> = this._tokenMetadata.asObservable();

  private _accessRightsId: BehaviorSubject<number> = new BehaviorSubject(null);
  private _selectedClientId: BehaviorSubject<number> = new BehaviorSubject(null);
  private _newClientId: Subject<number> = new Subject();
  public newClientIdObs$: Observable<number> = this._newClientId.asObservable();
  public refreshConfigToken: BehaviorSubject<any> = new BehaviorSubject(null);
  private _selectedClientImage: BehaviorSubject<string> = new BehaviorSubject(null);

  private _tileEdit: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public tileEdit$: Observable<boolean> = this._tileEdit.asObservable();

  private _termsAndConditionsAccepted: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private _visibleServiceTypeIds: number[] = [];
  private _clientIds: number[] = [];

  constructor() {
    const cName = localStorage.getItem('s_c_name');
    if (cName) {
      this.companyNameSet = cName;
    }
    const shortCompanyName = localStorage.getItem('s_c_short_name');
    if (shortCompanyName) {
      this.companyShortNameSet = shortCompanyName;
    }

    const companyId = localStorage.getItem('selected-client-id');
    if (companyId) {
      this._selectedClientId.next(parseInt(companyId));
    }
  }

  private allGroups = [
    GroupEnum.ClientAdmin,
    GroupEnum.ClientUser,
    GroupEnum.NewsecKeyAccountManager,
    GroupEnum.NewsecPortalAdmin,
    GroupEnum.NewsecPortalSystemAdmin,
    GroupEnum.NewsecUser,
    GroupEnum.NewsecServiceAdministrator
  ];

  public set userSet(val) {
    this._user.next(val);
  }

  public get user() {
    return this._user.value;
  }

  public set tokenSet(val) {
    this._tokenMetadata.next(val);
  }

  public set companyNameSet(val: string) {
    localStorage.setItem('s_c_name', val);
    this._companyName.next(val);
  }

  public set companyShortNameSet(val: string) {
    localStorage.setItem('s_c_short_name', val);
    this._shortCompanyName.next(val);
  }

  public set setUserGroups(val: string[]) {
    this._userGroups.next(val);
  }

  public get userCountry(): string {
    return this._tokenMetadata.value.ctry;
  }

  public get userLanguage(): string {
    return this._tokenMetadata.value.xms_pl;
  }

  public get userGroups(): string[] {
    return this._userGroups.value;
  }

  get selectedClientId(): number {
    return this._selectedClientId.value;
  }

  set selectedClientId(val: number) {
    this._selectedClientId.next(val);
    this._newClientId.next(val);
  }

  get accessRightsId(): number {
    return this._accessRightsId.value;
  }

  set accessRightsId(val: number) {
    this._accessRightsId.next(val);
  }

  get selectedClientImage(): string {
    return this._selectedClientImage.value;
  }

  set selectedClientImage(value: string) {
    this._selectedClientImage.next(value);
  }

  set visibleServiceTypeIds(values) {
    this._visibleServiceTypeIds = values;
  }

  get visibleServiceTypeIds() {
    return this._visibleServiceTypeIds;
  }

  set clientIds(values) {
    this._clientIds = values;
  }

  get clientIds() {
    return this._clientIds;
  }

  isNewsecRole(groups): boolean {
    const matches = this.allGroups.filter((group) => {
      return groups.includes(group.toString());
    });

    return (
      (matches.length === 1 && matches.includes(GroupEnum.NewsecPortalAdmin)) ||
      matches.includes(GroupEnum.NewsecPortalSystemAdmin) ||
      matches.includes(GroupEnum.NewsecUser) ||
      matches.includes(GroupEnum.NewsecKeyAccountManager) ||
      matches.includes(GroupEnum.NewsecServiceAdministrator)
    );
  }

  public isNewsecAdmin(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return matches.length === 1 && matches.includes(GroupEnum.NewsecPortalSystemAdmin);
  }

  public isClientAdmin(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return matches.length === 1 && matches.includes(GroupEnum.ClientAdmin);
  }

  public isNewsecPortalAdmin(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return matches.length === 1 && matches.includes(GroupEnum.NewsecPortalAdmin);
  }

  public isNewsecUser(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return matches.length === 1 && matches.includes(GroupEnum.NewsecUser);
  }

  public isNewsecKAM(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return matches.length === 1 && matches.includes(GroupEnum.NewsecKeyAccountManager);
  }

  public isAnyAdmin(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return (
      (matches.length === 1 && matches.includes(GroupEnum.NewsecPortalAdmin)) ||
      matches.includes(GroupEnum.NewsecPortalSystemAdmin) ||
      matches.includes(GroupEnum.ClientAdmin) ||
      matches.includes(GroupEnum.NewsecKeyAccountManager)
    );
  }

  public isKamOrPortalAdmin(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return (
      (matches.length === 1 && matches.includes(GroupEnum.NewsecPortalAdmin)) ||
      matches.includes(GroupEnum.NewsecKeyAccountManager) ||
      matches.includes(GroupEnum.ClientAdmin) ||
      matches.includes(GroupEnum.NewsecPortalSystemAdmin) ||
      matches.includes(GroupEnum.NewsecServiceAdministrator)
    );
  }

  public isRegularUser(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return (matches.length === 1 && matches.includes(GroupEnum.NewsecUser)) || matches.includes(GroupEnum.ClientUser);
  }

  public isNewsecServiceAdmin(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return matches.length === 1 && matches.includes(GroupEnum.NewsecServiceAdministrator);
  }

  public isNewsecPortalSystemAdmin(): boolean {
    const matches = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    return matches.length === 1 && matches.includes(GroupEnum.NewsecPortalSystemAdmin);
  }

  public getUserRole(): string {
    const userGroup = this.allGroups.filter((group) => {
      return this.userGroups.includes(group.toString());
    });

    if (userGroup.includes(GroupEnum.ClientAdmin)) {
      return 'Client Admin';
    }
    if (userGroup.includes(GroupEnum.ClientUser)) {
      return 'Client User';
    }
    if (userGroup.includes(GroupEnum.NewsecKeyAccountManager)) {
      return 'Newsec Key Account Manager';
    }
    if (userGroup.includes(GroupEnum.NewsecPortalAdmin)) {
      return 'Newsec Portal Admin';
    }
    if (userGroup.includes(GroupEnum.NewsecPortalSystemAdmin)) {
      return 'Newsec Portal System Admin';
    }
    if (userGroup.includes(GroupEnum.NewsecUser)) {
      return 'Newsec User';
    }
    if (userGroup.includes(GroupEnum.NewsecServiceAdministrator)) {
      return 'Newsec Service Administrator';
    }
  }

  get tileEdit(): boolean {
    return this._tileEdit.value;
  }

  set tileEdit(newValue: boolean) {
    this._tileEdit.next(newValue);
  }

  public set setTermsAndConditions(isAccepted: boolean) {
    this._termsAndConditionsAccepted.next(isAccepted);
  }

  public get termsAndConditions(): boolean {
    return this._termsAndConditionsAccepted.value;
  }
}
