import { NgModule } from '@angular/core';
import { PublicContactUsComponent } from './public-contact-us.component';
import { CommonModule } from '@angular/common';
import { FormFieldModule } from '@progress/kendo-angular-inputs';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { FileSelectModule } from '@progress/kendo-angular-upload';

@NgModule({
  declarations: [PublicContactUsComponent],
  exports: [PublicContactUsComponent],
  imports: [
    CommonModule,
    FormFieldModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    FileSelectModule
  ]
})
export class PublicContactUsModule {}
