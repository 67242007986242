<div>
  <div class="htmlblock">
    <div class="mb-4 container">
      <div class="row">
        <div class="col-12 col-md-8">
          <h2>Cookie Declaration</h2>
        </div>
        <div class="col-12">
          <div #cookieDeclaration>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
