import { HttpBackend, HttpClient, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IReferenceCase } from '../../core/interfaces/referenceCase.interface';

@Injectable()
export class ImportExportService {
  referenceCases$ = this.http.get(`${this.apiBaseUrl}/ReferenceCase/get-all`);
  private selectedReferenceCase: BehaviorSubject<IReferenceCase | null> = new BehaviorSubject(null);
  selectedReferenceCase$ = this.selectedReferenceCase.asObservable();
  constructor(
    private http: HttpClient,
    @Inject('API_BASE_URL') private apiBaseUrl: string,
    private httpBackend: HttpBackend
  ) {}

  setReferenceCase(referenceCase: IReferenceCase) {
    this.selectedReferenceCase.next(referenceCase);
  }

  sendEmail(data): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiBaseUrl}/Client/contact-us`, data);
  }
  sendEmailPublic(data): Observable<any> {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const request = new HttpRequest('POST', `${this.apiBaseUrl}/Public/contact-us`, formData);
    return this.httpBackend.handle(request);
  }

  get selectedReferenceCaseValue(): IReferenceCase {
    return this.selectedReferenceCase.value;
  }
}
