import { Injectable, TemplateRef } from '@angular/core';
import { ChatClient } from '@azure/communication-chat';
import { environment } from '../../../environments/environment';
import { CommunicationChatService } from './communicationChat.service';
import { UserStore } from './user-store.service';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { ChatMessageState } from '../interfaces/messageState.interface';
import { constants } from '../../dashboard/communication/communication-page/helpers/constants';
import { IAccessToken } from '../interfaces/accessTokenDTO.interface';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { TranslateService } from '@ngx-translate/core';
import { MentionNotificationBM } from '../bm/mentionNotificationBM.interface';
import { MentionNotificationComponent } from '../shared/components/mention-notification/mention-notification.component';

@Injectable({
  providedIn: 'root'
})
export class CommunicationNotificationService {
  private _chatClient: ChatClient;
  private endpointUrl: string = environment.communicationEndpointUrl;
  private _notificationRef: NotificationRef[] = [];

  constructor(
    private chatSvc: CommunicationChatService,
    private userStore: UserStore,
    private notificationSvc: NotificationService,
    private translateSvc: TranslateService
  ) {}

  public clearNotifications() {
    this._notificationRef.forEach((notif) => notif.notification.destroy());
    this._notificationRef = [];
  }

  async initChatClient() {
    const token = await this.refreshToken();
    if (!this._chatClient) {
      this._chatClient = new ChatClient(this.endpointUrl, new AzureCommunicationTokenCredential(token.token));
      await this.startChatClient();
    }
  }

  async startChatClient() {
    await this._chatClient.startRealtimeNotifications();
    this._chatClient.on('chatMessageReceived', (state: ChatMessageState) => {
      this.notifyUserOnMention(state);
    });
  }

  private notifyUserOnMention(state: ChatMessageState) {
    const taggedUsers: number[] = JSON.parse(state.metadata.taggedUsersId);
    if (taggedUsers.length > 0 && taggedUsers.includes(this.userStore.user.id)) {
      this.displayNotification('CommunicationTaggedTemplateTitle', state);
    }
  }

  private displayNotification(subject: string, state: ChatMessageState) {
    subject = this.translateSvc.instant(subject);
    subject = subject.replace('{threadName}', state.metadata.threadName);
    const notification = this.notificationSvc.show({
      content: MentionNotificationComponent,
      cssClass: 'sf-notification-clean',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'none', icon: true },
      //TODO REMOVE WHEN TESTING IS DONE
      hideAfter: environment['notificationTimeout'] || 5000,
      closable: false
    });
    const disableGoToCommunications = this.userStore.selectedClientId.toString() !== state.metadata['clientId'];
    const notificationContent = notification.content?.instance as MentionNotificationComponent;
    notificationContent.setValues(
      state.metadata.threadName,
      state.threadId,
      subject,
      state.message,
      {
        senderDisplayName: state.senderDisplayName,
        imagePath: state.metadata['imageUrl']
      },
      disableGoToCommunications
    );
    this.notificationRef.push(notification);
  }

  async stopChatClient() {
    await this._chatClient.stopRealtimeNotifications();
  }

  private async refreshToken(): Promise<IAccessToken> {
    let token: IAccessToken = JSON.parse(localStorage.getItem(constants.accessTokenKey));
    token = this.fixTokenDate(token);
    if (token && token.expiresOn > new Date()) {
      return token;
    }
    const newToken = await this.chatSvc.refreshToken(this.userStore.user.communicationServiceId).toPromise();
    localStorage.setItem(constants.accessTokenKey, JSON.stringify(this.fixTokenDate(newToken)));
    return newToken;
  }

  private fixTokenDate(token: IAccessToken): IAccessToken {
    if (!token) return;
    token.expiresOn = new Date(token.expiresOn);
    return token;
  }

  get chatClient() {
    return this._chatClient;
  }

  set chatClient(value: ChatClient) {
    this._chatClient = value;
  }

  get notificationRef(): NotificationRef[] {
    return this._notificationRef;
  }
}
