import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerInterceptor implements HttpInterceptor {
  private requestedRoutes = [];

  constructor(private loaderSvc: LoaderService) {}

  excludedRotues = [
    '/Home/get-homepage-config/',
    '/Home/save-homepage-config/',
    '/Home/get-client-data/',
    'User/check-user-azure/',
    'user/get-my-language',
    'User/get-my-language',
    'Home/get-user-info',
    'User/get-roles',
    'Home/get-session-lock-timeout',
    'InfoMessageDelivery/get-unread-count',
    'Home/get-user-clients',
    'User/update-active-client',
    'LocalizedResource/get-all',
    'Communication/threadId',
    'Communication/task-id',
    'Communication/get-profile-picture/',
    'Communication/get-profile-picture',
    'Communication/send-email',
    'Communication/update-last-message-read'
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isUrlExcluded(req.url)) {
      this.requestedRoutes.push(req.url);
      //console.log('spinSVC - POSTAVLJAM SPINNER NA ' + this.displaySpinner);
      this.loaderSvc.activeLoader = this.displaySpinner;
    }

    return next.handle(req).pipe(
      filter((e) => e instanceof HttpResponse),
      finalize(() => {
        const url = req.url;
        if (this.isUrlExcluded(url)) return;
        this.requestedRoutes = this.requestedRoutes.filter((route) => req.url !== route);
        //console.log('spinSVC - POSTAVLJAM SPINNER NA ' + this.displaySpinner);
        this.loaderSvc.activeLoader = this.displaySpinner;
      })
    );
  }
  get displaySpinner(): boolean {
    return !!this.requestedRoutes.length;
  }

  isUrlExcluded(requestUrl: string): boolean {
    return !!this.excludedRotues.find((excludedUrl: string) => requestUrl.includes(excludedUrl));
  }
}
