const domain = 'online.newsec.com';
const baseUrl = `https://${domain}/api`;
const envType = 'prod';
const communicationEndpointUrl = 'https://clientportal-com-service.communication.azure.com';

export const environment = {
  production: true,
  baseUrl,
  envType,
  communicationEndpointUrl,
  auth: {
    clientId: '67ef46db-2cd4-43e1-9c65-ad8c7148e4c4', //'23b211bd-7ea0-4360-9db0-79b6ca19a6b3',
    authority: 'https://login.microsoftonline.com/a76b06e3-f400-49db-84df-e9a75fa473f6', //'https://login.microsoftonline.com/47585125-5520-4f55-8c8c-e935085cc2f1',
    //redirectUri: 'https://localhost:44326',
    redirectUri: `https://${domain}/home/dashboard`,
    postLogoutRedirectUri: `https://${domain}`
  },
  apiUrl: ['http://clientportal-api-prod-app/NewsecAPIProd'],
  recaptchaKey: '6LfQReIpAAAAAF2uNJcxq1X-Y3ybpjddDww4Mrzs'
};

export enum GroupEnum {
  ClientAdmin = 'e8f01862-0e0b-41fb-8e40-c30158188eac',
  //ClientSignatory = 'fe501f74-9789-401e-aeae-38882903764f',
  ClientUser = '6561addc-6942-436f-b9ca-08d7dce92f31',
  NewsecKeyAccountManager = 'a388ee88-7f70-4b9a-b2f2-2765f7d2e9df',
  NewsecPortalAdmin = '00f52825-15e9-46d9-aa2e-5d13e7218085',
  NewsecPortalSystemAdmin = '62fbdba1-672c-49b2-9a98-96414f7ad343',
  //NewsecSalesManager = '48238917-c25c-43a5-aeff-438955455aa0',
  //NewsecServiceDesk = '72d4dc13-cea3-4c44-a039-3c3aee96ace3',
  NewsecUser = '3b85519e-d4d3-48ce-9e07-2983fc5dcb5d',
  NewsecServiceAdministrator = '837e3aaa-7824-4aa0-954c-1b2a66deae91'
}
