import {  Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'communicationDate'
  })
export class CommunicationDatePipe implements PipeTransform {
    transform(value: string): string {
        const datePipe = new DatePipe('en-US');
        const today = new Date();
        const dateValue = new Date(value);

        if (today.getDate() === dateValue.getDate() && today.getMonth() === dateValue.getMonth() && today.getFullYear() === dateValue.getFullYear()) 
        {
            return datePipe.transform(value, 'HH:mm');
        } 
        else 
        {
            return datePipe.transform(value, 'MMM d');
        }
    }
}
  