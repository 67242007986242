import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export class RemoteTranslationsLoader implements TranslateLoader {
  constructor(private http: HttpClient, private group: string) {}
  getTranslation(lang: string): Observable<any> {
    return this.http
      .get(`${environment.baseUrl}/LocalizedResource/get-all?lang=${lang}&group=${this.group}`)
      .pipe(retry(1));
  }
}
