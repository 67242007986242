import { Component, Input } from '@angular/core';
import { IUser } from '../../../interfaces/user.interface';
import { UserStore } from '../../../services/user-store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-task-notification-template',
  templateUrl: './task-notification-template.component.html',
  styleUrls: ['./task-notification-template.component.scss']
})
export class TaskNotificationTemplateComponent {
  subject: string;
  text: string;
  taskTitle: string;
  redirectUrl: string | null = null;
  requester: IUser | null = null;
  clientId: number;
  selectedClientId: number;

  ngOnInit(): void {
    this.selectedClientId = this.userStore.selectedClientId;
  }

  constructor(private userStore: UserStore) {}

  checkSelectedClient() {
    return this.selectedClientId == this.clientId;
  }

  public setValues(
    subject: string,
    text: string,
    taskTitle: string,
    redirectUrl = null,
    requester = null,
    clientId: number
  ) {
    this.subject = subject;
    this.text = text;
    this.taskTitle = taskTitle;
    this.redirectUrl = redirectUrl;
    this.requester = requester;
    this.clientId = clientId;
  }
}
