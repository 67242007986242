<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  style="margin: auto; display: block"
  width="200px"
  height="200px"
  viewBox="0 0 100 100"
  preserveAspectRatio="xMidYMid"
>
  <circle
    cx="50"
    cy="50"
    fill="none"
    stroke="#4F6CF5"
    stroke-width="6"
    r="32"
    stroke-dasharray="150.79644737231007 52.26548245743669"
  >
    <animateTransform
      attributeName="transform"
      type="rotate"
      repeatCount="indefinite"
      dur="2.6315789473684212s"
      values="0 50 50;360 50 50"
      keyTimes="0;1"
    ></animateTransform>
  </circle>
</svg>
