import { ChangeDetectorRef, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthServiceWrapper } from './core/services/auth.service';
import { BreadcrumbService } from './core/services/breadcrumb.service';
import { LoaderService } from './core/services/loader.service';

const ROUTE_TITLE_DICTIONARY = {
  '/home/services': ''
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  titleBase = 'Newsec Online';
  spinner$ = this.loaderSvc.active.pipe(
    tap((e) => {
      this.cdRef.detectChanges();
    })
  );
  constructor(
    private router: Router,
    private translateSvc: TranslateService,
    private titleSvc: Title,
    private route: ActivatedRoute,
    private auth: AuthServiceWrapper,
    private bCrumb: BreadcrumbService,
    public loaderSvc: LoaderService,
    private cdRef: ChangeDetectorRef
  ) {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((t) => {
          const title = this.findTitleInRoute(this.route);
          return title;
        }),
        switchMap((t: string) => {
          if (t) {
            return this.translateSvc.get(t);
          } else {
            return of('');
          }
        })
      )
      .subscribe((suffix) => {
        const title = `${this.titleBase} ${suffix ? '-' : ''} ${suffix}`;
        this.titleSvc.setTitle(title);
      });
    window.addEventListener('storage', (ev: StorageEvent) => {
      if (ev && ev.key === 'ns-logout' && ev.newValue === '1') {
        window.removeEventListener('storage', () => {
          this.auth.logout();
        });
      }
    });
  }

  findTitleInRoute(route: ActivatedRoute): string {
    if ('title' in route.snapshot.data) {
      return route.snapshot.data['title'];
    }
    return route.firstChild ? this.findTitleInRoute(route.firstChild) : '';
  }
}
