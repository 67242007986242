import { NgModule } from '@angular/core';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { NewsecErrorComponent } from './newsec-error/newsec-error.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { RoleDirective } from '../helpers/role.directive';
import { NsCardComponent } from './components/ns-card/ns-card.component';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DisplayAccessRightsName } from '../pipes/display-access-rights-name.pipe';
import { CardComponent } from './components/card/card.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { TableTileComponent } from './components/table-tile/table-tile.component';
import { SatisfactionComponent } from './components/satisfaction/satisfaction.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { SparklineComponent } from './components/sparkline/sparkline.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { BuildingChartComponent } from './components/building-chart/building-chart.component';
import { NsCheckboxComponent } from './components/ns-checkbox/ns-checkbox.component';
import { NsLoaderComponent } from './components/ns-loader/ns-loader.component';
import { ListTileComponent } from './components/list-tile/list-tile.component';
import { FormTileComponent } from './components/form-tile/form-tile.component';
import { StatisticArrowComponent } from './components/statistic-arrow/statistic-arrow.component';
import { ServiceDataDialogComponent } from './components/service-data-dialog/service-data-dialog.component';
import { RangeBarComponent } from './components/range-bar/range-bar.component';
import { AreaChartComponent } from './components/area-chart/area-chart.component';
import { NsLinkCardComponent } from './components/ns-link-card/ns-link-card.component';
import { ExtensionPipe } from '../pipes/extension.pipe';
import { CommunicationDatePipe } from '../pipes/communication-date.pipe';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TaskService } from '../services/task.service';
import { TaskCategoryService } from '../../dashboard/communication/categories-page/categories-page.service';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { TaskNotificationTemplateComponent } from './components/task-notification-template/task-notification-template.component';
import { MentionNotificationComponent } from './components/mention-notification/mention-notification.component';

@NgModule({
  declarations: [
    NewsecErrorComponent,
    SearchComponent,
    RoleDirective,
    NsCardComponent,
    BreadcrumbComponent,
    DisplayAccessRightsName,
    CardComponent,
    BarChartComponent,
    PieChartComponent,
    TableTileComponent,
    SatisfactionComponent,
    SparklineComponent,
    BuildingChartComponent,
    NsCheckboxComponent,
    NsLoaderComponent,
    ListTileComponent,
    StatisticArrowComponent,
    ServiceDataDialogComponent,
    RangeBarComponent,
    AreaChartComponent,
    NsLinkCardComponent,
    ExtensionPipe,
    CommunicationDatePipe,
    FormTileComponent,
    TaskNotificationTemplateComponent,
    MentionNotificationComponent
  ],
  imports: [
    CommonModule,
    GridModule,
    InputsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ChartsModule,
    DropDownsModule,
    FormsModule,
    ButtonsModule,
    DropDownsModule,
    LabelModule
  ],
  exports: [
    NewsecErrorComponent,
    SearchComponent,
    RoleDirective,
    NsCardComponent,
    BreadcrumbComponent,
    GridModule,
    DisplayAccessRightsName,
    CardComponent,
    BarChartComponent,
    PieChartComponent,
    TableTileComponent,
    SatisfactionComponent,
    SparklineComponent,
    BuildingChartComponent,
    NsCheckboxComponent,
    NsLoaderComponent,
    ListTileComponent,
    StatisticArrowComponent,
    ServiceDataDialogComponent,
    RangeBarComponent,
    AreaChartComponent,
    NsLinkCardComponent,
    CommunicationDatePipe,
    FormTileComponent,
    TaskNotificationTemplateComponent,
    MentionNotificationComponent
  ],
  providers: [DisplayAccessRightsName, CommunicationDatePipe, TaskService, TaskCategoryService, TranslateService],
  entryComponents: [NewsecErrorComponent]
})
export class SharedModule {}
