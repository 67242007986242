import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAccessToken } from '../interfaces/accessTokenDTO.interface';
import { MentionNotificationBM } from '../bm/mentionNotificationBM.interface';

@Injectable({
  providedIn: 'root'
})
export class CommunicationChatService {
  private serviceBaseUrl = '';

  constructor(private http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {
    this.serviceBaseUrl = `${this.baseUrl}/Communication`;
  }

  refreshToken(commServiceId: string): Observable<IAccessToken> {
    return this.http.get<any>(`${this.serviceBaseUrl}/refresh-Token/${commServiceId}`);
  }

  sendEmailToMentionedUsers(mentionNotification: MentionNotificationBM): Observable<boolean> {
    return this.http.put<boolean>(`${this.serviceBaseUrl}/send-email`, mentionNotification);
  }
}
