//Angular Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, Title } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

//Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

//Layout Service - Required
import { pagesToggleService } from './@pages/services/toggler.service';

//Shared Layout Components

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

//Sample Blank Pages - Optional
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HttpClientTrans } from './core/services/http-translation.service';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { environment } from '../environments/environment';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { CondensedComponent } from './@pages/layouts';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CookieDeclarationComponent } from './cookie-declaration/cookie-declaration.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { RemoteTranslationsLoader } from './helpers/remote-translation-loader';
import { HttpErrorInterceptor } from './core/services/error-intercetor.service';
import { LanguageInterceptor } from './core/services/language-interceptor.service';
import { SpinnerInterceptor } from './core/services/spinner.service';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';
import { OverlayModule } from '@angular/cdk/overlay';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { SharedModule } from './core/shared/shared.module';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ImportExportService } from './dashboard/import-export/import-export.service';
import { PublicContactUsModule } from './public/public-contact-us/public-contact-us.module';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaV3Module } from 'ng-recaptcha';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';

const lang = navigator.language.split('-')[0] ? navigator.language.split('-')[0] : 'en';

//Hammer Config Overide
//https://github.com/angular/angular/issues/10541
@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

export function createTranslateLoader(http: HttpClientTrans) {
  return new RemoteTranslationsLoader(http, '');
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: environment.auth,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(environment.baseUrl, environment.apiUrl);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: {
      prompt: 'none'
    }
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  declarations: [
    AppComponent,
    CondensedComponent,
    LandingPageComponent,
    NotFoundPageComponent,
    CookieDeclarationComponent,
    ForbiddenPageComponent,
    ThankYouPageComponent,
    LogoutPageComponent,
    NoAccessComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
      // Don't perform initial navigation in iframes
      // initialNavigation: !isIframe ? 'enabled' : 'disabled'
    }),
    TranslateModule.forRoot({
      defaultLanguage: lang,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClientTrans]
      },
      isolate: false
    }),
    ButtonsModule,
    GridModule,
    ChartsModule,
    DialogsModule,
    InputsModule,
    UploadModule,
    DropDownsModule,
    LabelModule,
    OverlayModule,
    LayoutModule,
    DropDownsModule,
    SharedModule,
    NotificationModule,
    ReactiveFormsModule,
    PublicContactUsModule,
    RecaptchaV3Module,
    RecaptchaFormsModule
  ],
  providers: [
    pagesToggleService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    GoogleTagManagerService,
    Title,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig
    },
    {
      provide: 'API_BASE_URL',
      useValue: environment.baseUrl
    },
    {
      provide: 'USERS_BROWSER_LANG',
      useValue: lang
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-MS44BTS'
    },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptchaKey } },
    ImportExportService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
