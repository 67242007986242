import { Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { CookieDeclarationComponent } from './cookie-declaration/cookie-declaration.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { SessionGuard } from './core/guards/session.guard';
import { NoAccessComponent } from './no-access/no-access.component';
import { PublicContactUsComponent } from './public/public-contact-us/public-contact-us.component';
import { MsalGuard } from '@azure/msal-angular';

const lang = navigator.language.split('-')[0] ? navigator.language.split('-')[0] : 'en';

export const AppRoutes: Routes = [
  { path: '', redirectTo: `${lang}/home`, pathMatch: 'full' },
  {
    path: 'home',
    // loadChildren: './dashboard/dashboard.module#DashboardModule',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [SessionGuard, MsalGuard]
  },
  {
    path: 'code',
    // loadChildren: './dashboard/dashboard.module#DashboardModule',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [SessionGuard, MsalGuard]
  },
  {
    path: `:lang/home`,
    component: LandingPageComponent,
    data: {
      language: lang,
      gaTitle: 'Landing page'
    }
  },
  {
    path: 'cookies',
    component: CookieDeclarationComponent,
    data: {
      gaTitle: 'Cookie declaration'
    }
  },
  {
    path: 'forbidden',
    component: ForbiddenPageComponent,
    data: {
      gaTitle: 'Forbidden page'
    }
  },
  {
    path: 'tpc',
    component: ThankYouPageComponent,
    data: {
      language: lang,
      gaTitle: 'Thank you page'
    }
  },
  {
    path: 'error',
    component: LogoutPageComponent,
    data: {
      gaTitle: 'Error page'
    }
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
    data: {
      gaTitle: 'Logout page'
    }
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: {
      gaTitle: 'No access page'
    }
  },
  // {
  //   path: ':lang/contact',
  //   component: PublicContactUsComponent,
  //   data: {
  //     language: lang,
  //     gaTitle: 'Contact us'
  //   }
  // },
  {
    path: '**',
    component: NotFoundPageComponent,
    data: {
      language: lang,
      gaTitle: 'Not found page'
    }
  }
];
