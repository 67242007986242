<div class="login-home">
  <div class="bg-pic"></div>
  <div class="mainContainer">
    <div class="col-12">
      <img src="../../assets/img/newsec-logo.svg" alt="logo" height="30" />
      <h2 class="sf-wellcome-title">Newsec Online</h2>
      <form class="p-t-15" role="form" #loginForm="ngForm">
        <a class="newsec-btn" type="submit" [class.disabled]="loginForm.invalid" (click)="clearSessionLock()">{{
          'adLogin' | translate
        }}</a>
      </form>
    </div>
  </div>
</div>
