import { Injectable } from '@angular/core';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private messageSvc: MessageService) {}

  success(msg: string) {
    this.messageSvc.success(msg);
  }

  error(err) {
    this.messageSvc.error(err);
  }

  info(msg: string) {
    this.messageSvc.info(msg);
  }

  taskInfo(data: any): void {
    this.messageSvc.taskInfo(data);
  }
}
