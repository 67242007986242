import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthServiceWrapper } from '../core/services/auth.service';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { UserStore } from '../core/services/user-store.service';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss', '../@pages/layouts/condensed/condensed.component.scss'],
})
export class ThankYouPageComponent {
  supportedLangs: string[] = ['en', 'sv', 'fi', 'no', 'da'];
  constructor(
    private route: ActivatedRoute,
    private translateSvc: TranslateService,
    private auth: AuthServiceWrapper,
    private userStore: UserStore,
    private googleAnalyticsSvc: GoogleAnalyticsService
  ) {
    let lang = this.route.snapshot.data.language;
    if (!this.supportedLangs.includes(lang)) {
      lang = 'en';
    }

    if (this.auth.getAccount()) {
      try {
        lang = this.userStore.userLanguage;
      } catch (e) {
        lang = 'en';
      }
    }
    this.translateSvc.use(lang);
    this.userStore.userObs$.subscribe((result) => {
      if (result) {
        this.googleAnalyticsSvc.sendTag();
      } else {
        this.googleAnalyticsSvc.sendUnknownTag();
      }
    });
  }
}
