import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mention-notification',
  templateUrl: './mention-notification.component.html',
  styleUrls: ['./mention-notification.component.scss']
})
export class MentionNotificationComponent implements OnInit {
  threadName: string;
  threadId: string;
  message: string;
  subject: string;
  requester: { senderDisplayName: string; imagePath: string };
  disableClick: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToCommunications() {
    if (this.disableClick) {
      return;
    }
    this.router.navigate([`/home/communication/threadId/${this.threadId}`]);
  }

  public setValues(
    threadName: string,
    threadId: string,
    subject: string,
    message: string,
    requester: { senderDisplayName: string; imagePath: string },
    disableClick: boolean
  ) {
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = message;
    var messageText = tempDiv.innerText.trim();
    this.threadName = threadName;
    this.threadId = threadId;
    this.subject = subject;
    this.message = messageText;
    this.requester = requester;
    this.disableClick = disableClick;
  }
}
