import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter, take } from 'rxjs/operators';
import { UserStore } from './user-store.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private userStore: UserStore
  ) {}

  sendTag(): void {
    const pageName = this.findGATitleInRoute(this.route);
    this.sendTagWithPageName(pageName);
  }

  sendTagWithPageName(pageName: string): void {
    this.userStore.shortCompanyName$.pipe(take(1)).subscribe((clientShortName) => {
      const userRole = this.userStore.getUserRole();
      if (userRole == null) {
        this.sendUnknownTag();
        return;
      }
      const gtmTag = {
        event: 'page_view',
        client: clientShortName,
        role: userRole,
        page: pageName
      };
      const gtmScript = document.getElementById('GTMscript');
      if (gtmScript) {
        const attr = gtmScript.getAttribute('data-cookieconsent');
        if (!attr) {
          gtmScript.setAttribute('data-cookieconsent', 'ignore');
        }
      }
      // console.log(gtmTag);
      this.gtmService.pushTag(gtmTag);
    });
  }

  sendUnknownTag(): void {
    const pageName = this.findGATitleInRoute(this.route);
    const gtmTag = {
      event: 'page_view',
      client: 'Unknown',
      role: 'Unknown',
      page: pageName
    };
    // console.log(gtmTag);
    const gtmScript = document.getElementById('GTMscript');
    if (gtmScript) {
      const attr = gtmScript.getAttribute('data-cookieconsent');
      if (!attr) {
        gtmScript.setAttribute('data-cookieconsent', 'ignore');
      }
    }
    this.gtmService.pushTag(gtmTag);
  }

  findGATitleInRoute(route: ActivatedRoute): string {
    if ('gaTitle' in route.snapshot.data) {
      return route.snapshot.data['gaTitle'];
    }

    return route.firstChild ? this.findGATitleInRoute(route.firstChild) : '';
  }
}
